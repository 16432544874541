
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";

@Component
export default class errPage extends Vue
{

    /**
     * 当前机构简称
     * @private
     * @returns void
     */
    private get orgName(): any
    {
        return this.$route.params && this.$route.params.orgName;
    }

}
